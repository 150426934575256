import React from "react"
import { preToCodeBlock } from "utils/pre-to-code-block"
import StyledCode from "components/code/code"
import { MDXProvider } from "@mdx-js/react"

const components = {
  pre: preProps => {
    const props = preToCodeBlock(preProps)
    if (props) {
      return <StyledCode {...props} />
    }
    return <pre {...preProps} />
  },
  wrapper: ({ children }) => <>{children}</>,
}

export const wrapPageElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
)
