import React from "react"
import PropTypes from "prop-types"
import { Highlight, themes } from "prism-react-renderer"
import styled from "styled-components"

// @see https://www.themoderncoder.com/gatsby-with-prismjs-and-mdx/
export const Code = ({ className, codeString, language, ...props }) => (
  <div className={className}>
    <Highlight code={codeString} language={language} theme={themes.github}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <div className="gatsby-highlight" data-language={language}>
          <pre className={className} style={style}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })} key={i}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} key={key} />
                ))}
              </div>
            ))}
          </pre>
        </div>
      )}
    </Highlight>
  </div>
)

const StyledCode = styled(Code)`
  margin-bottom: 1.75rem;
  font-size: 0.9rem;
  line-height: 1.5rem;
`

Code.propTypes = {
  codeString: PropTypes.string.isRequired,
  language: PropTypes.string,
}

Code.defaultProps = {
  language: "javascript",
}

export default StyledCode
