exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---themes-dry-mdx-src-pages-404-js": () => import("./../../../../../themes/dry-mdx/src/pages/404.js" /* webpackChunkName: "component---themes-dry-mdx-src-pages-404-js" */),
  "component---themes-dry-mdx-src-templates-mdx-category-js-content-file-path-contents-categories-it-it-cocktail-alcolici-cocktail-alcolici-md": () => import("./../../../../../themes/dry-mdx/src/templates/mdx-category.js?__contentFilePath=/Users/simonemoro/Code/bufox/gatsby/sites/azcocktails.it/contents/categories/it_IT/cocktail-alcolici/cocktail-alcolici.md" /* webpackChunkName: "component---themes-dry-mdx-src-templates-mdx-category-js-content-file-path-contents-categories-it-it-cocktail-alcolici-cocktail-alcolici-md" */),
  "component---themes-dry-mdx-src-templates-mdx-category-js-content-file-path-contents-categories-it-it-cocktail-analcolici-cocktail-analcolici-md": () => import("./../../../../../themes/dry-mdx/src/templates/mdx-category.js?__contentFilePath=/Users/simonemoro/Code/bufox/gatsby/sites/azcocktails.it/contents/categories/it_IT/cocktail-analcolici/cocktail-analcolici.md" /* webpackChunkName: "component---themes-dry-mdx-src-templates-mdx-category-js-content-file-path-contents-categories-it-it-cocktail-analcolici-cocktail-analcolici-md" */),
  "component---themes-dry-mdx-src-templates-mdx-page-homepage-js-content-file-path-contents-pages-it-it-homepage-homepage-md": () => import("./../../../../../themes/dry-mdx/src/templates/mdx-page-homepage.js?__contentFilePath=/Users/simonemoro/Code/bufox/gatsby/sites/azcocktails.it/contents/pages/it_IT/homepage/homepage.md" /* webpackChunkName: "component---themes-dry-mdx-src-templates-mdx-page-homepage-js-content-file-path-contents-pages-it-it-homepage-homepage-md" */),
  "component---themes-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-chi-siamo-chi-siamo-md": () => import("./../../../../../themes/dry-mdx/src/templates/mdx-page.js?__contentFilePath=/Users/simonemoro/Code/bufox/gatsby/sites/azcocktails.it/contents/pages/it_IT/chi-siamo/chi-siamo.md" /* webpackChunkName: "component---themes-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-chi-siamo-chi-siamo-md" */),
  "component---themes-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-contatti-contatti-md": () => import("./../../../../../themes/dry-mdx/src/templates/mdx-page.js?__contentFilePath=/Users/simonemoro/Code/bufox/gatsby/sites/azcocktails.it/contents/pages/it_IT/contatti/contatti.md" /* webpackChunkName: "component---themes-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-contatti-contatti-md" */),
  "component---themes-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-privacy-e-cookie-policy-privacy-e-cookie-policy-md": () => import("./../../../../../themes/dry-mdx/src/templates/mdx-page.js?__contentFilePath=/Users/simonemoro/Code/bufox/gatsby/sites/azcocktails.it/contents/pages/it_IT/privacy-e-cookie-policy/privacy-e-cookie-policy.md" /* webpackChunkName: "component---themes-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-privacy-e-cookie-policy-privacy-e-cookie-policy-md" */),
  "component---themes-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-termini-e-condizioni-termini-e-condizioni-md": () => import("./../../../../../themes/dry-mdx/src/templates/mdx-page.js?__contentFilePath=/Users/simonemoro/Code/bufox/gatsby/sites/azcocktails.it/contents/pages/it_IT/termini-e-condizioni/termini-e-condizioni.md" /* webpackChunkName: "component---themes-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-termini-e-condizioni-termini-e-condizioni-md" */),
  "component---themes-dry-mdx-src-templates-mdx-post-js-content-file-path-contents-posts-it-it-bloody-mary-bloody-mary-md": () => import("./../../../../../themes/dry-mdx/src/templates/mdx-post.js?__contentFilePath=/Users/simonemoro/Code/bufox/gatsby/sites/azcocktails.it/contents/posts/it_IT/bloody-mary/bloody-mary.md" /* webpackChunkName: "component---themes-dry-mdx-src-templates-mdx-post-js-content-file-path-contents-posts-it-it-bloody-mary-bloody-mary-md" */),
  "component---themes-dry-mdx-src-templates-mdx-post-js-content-file-path-contents-posts-it-it-margarita-margarita-md": () => import("./../../../../../themes/dry-mdx/src/templates/mdx-post.js?__contentFilePath=/Users/simonemoro/Code/bufox/gatsby/sites/azcocktails.it/contents/posts/it_IT/margarita/margarita.md" /* webpackChunkName: "component---themes-dry-mdx-src-templates-mdx-post-js-content-file-path-contents-posts-it-it-margarita-margarita-md" */)
}

