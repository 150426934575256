module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960,"linkImagesToOriginal":false,"withWebp":true,"showCaptions":["title"],"markdownCaptions":true,"backgroundColor":"white","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Azcocktails.it","description":"azcocktails-it","short_name":"dry-mdx","start_url":"/","background_color":"#ffffff","theme_color":"#00619b","display":"minimal-ui","icon":"/Users/simonemoro/Code/bufox/gatsby/themes/dry-mdx/assets/images/logo-square.svg","icon_options":{"purpose":"any maskable"},"lang":"it","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8ff96c401bcf6c19457cd41bb13d7bf9"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../themes/dry-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"it_IT","theme":{"enableLogo":true,"it_IT":{"siteTitle":"Azcocktails.it","siteDescription":"Tutto sui cocktail"}},"pluginsOverrideOptions":[{"plugin":"gatsby-plugin-manifest","options":{"name":"Azcocktails.it","short_name":"dry-mdx","description":"azcocktails-it","lang":"it","background_color":"#ffffff","theme_color":"#00619b"}}]},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
